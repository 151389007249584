import React, { useCallback } from 'react';
import { useController } from 'react-hook-form';
import { uniqBy } from 'lodash-es';

import { configSelectors } from '@infrastructure/store/config/configSelectors';
import { AppPropertyForm } from '@domain/models/appProperty/AppPropertyInput';
import { FormComponent } from '@ui/hooks/form';
import { useSelector } from '@ui/hooks/redux';
import { ReactComponent as CrossSvg } from '@assets/svg/cross.svg';

import styles from './GameeList.module.scss';

type Props = Pick<FormComponent<AppPropertyForm>, 'control'> & {
  index: number;
};
export function GameList({ control, index }: Props) {
  const games = useSelector(configSelectors.getGameApplicationList);
  const { field } = useController({ control, name: `property.${index}.value` });
  const gameIdList = Array.isArray(field.value) ? field.value : field.value.split(',');

  const handleRemove = useCallback(
    (event) => {
      const { name } = event.target.dataset;
      const game = games.find((g) => g.id.toString() === name);
      const chosenGames = games.filter((g) => g.gameName === game?.gameName);
      const ids = chosenGames.map((g) => g.id.toString());

      const formattedValue = gameIdList.filter((id) => !ids.includes(id)).join(',');

      field.onChange(formattedValue);
    },
    [field, games, gameIdList]
  );

  const gamesFiltered = games.filter((item) => gameIdList.includes(item.id.toString()));
  const uniqGamesFiltered = uniqBy(gamesFiltered, (g) => g.gameName);

  const labels = uniqGamesFiltered.map(({ id, gameName }) => (
    <li key={id} className={styles.label}>
      {gameName}
      <button type="button" data-name={id}>
        <CrossSvg />
      </button>
    </li>
  ));

  return (
    <ul className={styles.list} onClick={handleRemove}>
      {labels}
    </ul>
  );
}
