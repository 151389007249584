import { Service } from 'typedi';

import { AppliedConfigUseCase } from '@domain/useCases/AppliedConfigUseCase';
import { AppliedConfigApi } from '@infrastructure/api/AppliedConfigApi';
import { ConfigService } from '@app/services/ConfigService';
import { AppliedConfigViewDto } from '@domain/models/appliedConfig/AppliedConfigViewDto';
import { AppliedConfigMapper } from '@app/mappers/AppliedConfigMapper';
import { NotFoundError } from '@app/errors/NotFoundError';
import { ReduxRepo } from '@infrastructure/repositories/ReduxRepo';
import { appliedConfigSelectors } from '@infrastructure/store/appliedConfig/appliedConfigSelectors';
import { ExperimentApi } from '@infrastructure/api/ExperimentApi';
import { UniqueId } from '@domain/types';
import { ExperimentDto } from '@domain/models/experiment/ExperimentDto';

@Service()
export class AppliedConfigService implements AppliedConfigUseCase {
  constructor(
    private readonly appliedConfigApi: AppliedConfigApi,
    private readonly configService: ConfigService,
    private readonly experiment: ExperimentApi,
    private readonly reduxRepo: ReduxRepo
  ) {}

  async getExperiment(id: UniqueId): Promise<ExperimentDto> {
    return this.experiment.getExperiment(id);
  }

  async getGamesWithConfigs(): Promise<AppliedConfigViewDto[]> {
    const configFilters = this.reduxRepo.findBy(appliedConfigSelectors.getFormatFilters);

    const [gameList, configList, statsList] = await Promise.all([
      this.configService.getApps(),
      this.appliedConfigApi.getConfigList(configFilters),
      this.appliedConfigApi.getConfigStats().catch(() => null) // Optional API
    ]);

    if (!configList || !gameList) {
      return [];
    }

    const gameIds = [...new Set(configList.map((x) => x.gameId))];

    return gameIds.map((gameId) => {
      const filteredConfigs = configList.filter((x) => x.gameId === gameId);
      const stats = statsList?.find((x) => x.gameId === gameId);
      const game = gameList.find((x) => x.id === gameId);

      if (!game) {
        throw new NotFoundError('Game', gameId);
      }

      return AppliedConfigMapper.mapEntitiesToViewDto(game, filteredConfigs, stats);
    });
  }
}
