import React from 'react';
import { Button, ButtonVariant } from 'crazy-ui-next';

import { ReactComponent as ArrowSvg } from '@assets/svg/arrow-up-circle.svg';

import styles from '@pages/createExperiment/CreateExperiment.module.scss';

export function NextButton() {
  return (
    <Button type="submit" variant={ButtonVariant.PRIMARY} icon={<ArrowSvg />} className={styles.nextBtn}>
      Next
    </Button>
  );
}
