import React, { useCallback } from 'react';
import { useController } from 'react-hook-form';
import { DropdownMultiInput } from 'crazy-ui-next';
import { uniqBy } from 'lodash-es';
import { Tooltip } from 'crazy-ui';

import { configSelectors } from '@infrastructure/store/config/configSelectors';
import { AppPropertyForm } from '@domain/models/appProperty/AppPropertyInput';
import { GameList } from '@pages/appProperties/recommendationGameList/GameList';
import { FormComponent } from '@ui/hooks/form';
import { useSelector } from '@ui/hooks/redux';
import { ReactComponent as HintSvg } from '@assets/svg/hint.svg';

import styles from './AppProperties.module.scss';

type Props = Pick<FormComponent<AppPropertyForm>, 'control'> & {
  index: number;
  hint: string;
};

export function AppPropertiesGamesInput({ control, index, hint }: Props) {
  const games = useSelector(configSelectors.getGameApplicationList);
  const uniqGames = uniqBy(games, (g) => g.gameName);

  const options = uniqGames.map(({ id, gameName }) => ({
    label: gameName,
    value: id.toString()
  }));
  const { field } = useController({ control, name: `property.${index}.value` });
  const gameIdList = Array.isArray(field.value) ? field.value : field.value.split(',');

  const handleChange = useCallback(
    (event) => {
      const game = games.find((g) => g.id.toString() === event.value);
      const chosenGames = games.filter((g) => g.gameName === game?.gameName);
      const ids = chosenGames.map((g) => g.id.toString());

      if (gameIdList.includes(event.value)) {
        const formattedValue = gameIdList.filter((id) => !ids.includes(id)).join(',');

        field.onChange(formattedValue);
      } else {
        const formattedValue = [field.value, ...ids].join(',');

        field.onChange(formattedValue);
      }
    },
    [field, games, gameIdList]
  );

  return (
    <div className={styles.recommendationGames}>
      <div>
        <DropdownMultiInput
          name={`property.${index}.value`}
          labelText="Recommendation games high interval"
          placeholder="Select games"
          value={gameIdList}
          options={options}
          handleChange={handleChange}
        />
        <Tooltip text={hint}>
          <HintSvg />
        </Tooltip>
      </div>
      <GameList control={control} index={index} />
    </div>
  );
}
