import React, { useCallback } from 'react';
import { InputLabel, TextInput } from 'crazy-ui-next';
import { useFieldArray } from 'react-hook-form';

import { ObjectiveConfigParams } from '@domain/models/createExperiment/ObjectiveConfigParams';
import { MultiTextInputContainer } from '@pages/winnerConfigModal/form/inputs/multiTextInput/MultiTextInputContainer';
import { FormComponent, getInputProps } from '@ui/hooks/form';

import styles from '@pages/createExperiment/objectiveConfig/forms/AB/components/inputGroup/InputGroup.module.scss';

type Props = Pick<FormComponent<ObjectiveConfigParams>, 'control' | 'register'> & {
  sessionIndex: number;
  sectionIndex: number;
  name: string;
  labelText: string;
};

const maxInputsLength = 5;

export function ControlledTextInput({ control, register, name, labelText, sessionIndex, sectionIndex }: Props) {
  const { fields, append, remove } = useFieldArray({
    control,
    name: `input.${sectionIndex}.section.${sessionIndex}.${name}`
  });

  const onAdd = useCallback(() => {
    // @ts-ignore react-hook-form doesn't support dynamic name path and return type never
    append({ value: '' });
  }, [append]);

  const onRemove = useCallback(() => {
    remove(fields.length - 1);
  }, [remove, fields]);

  const inputs = fields.map((field, index) => {
    return (
      <TextInput
        // @ts-ignore react-hook-form doesn't support dynamic name path and return type never
        key={field.id}
        {...getInputProps<ObjectiveConfigParams>(`input.${sectionIndex}.section.${index}.${name}.value`, register, {})}
      />
    );
  });

  return (
    <>
      <InputLabel labelText={labelText} className={styles.label} />
      <MultiTextInputContainer inputs={inputs} onAddItem={onAdd} onRemoveItem={onRemove} maxItems={maxInputsLength} />
    </>
  );
}
