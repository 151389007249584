import { IsArray, IsBoolean, IsNotEmpty, IsNumberString, IsString, ValidateNested } from 'class-validator';
import { Type } from 'class-transformer';
import { ExpFormSummary } from '@pages/createExperiment/atoms/Summary/Summary';

export class GLDFormInput {
  constructor(key: string, value: string | null) {
    this.key = key;
    this.value = value;
  }

  key: string;
  value: string | null;
}

export class GLDFormConfig {
  constructor(name: string, input: GLDFormInput[], description: string) {
    this.name = name;
    this.input = input;
    this.description = description;
  }

  name: string;
  description: string;
  input: GLDFormInput[];
}

class Param {
  @IsString()
  @IsNotEmpty()
  value: string;
}

export class GLDConfigParams {
  @IsString()
  defaultValue: string;

  @IsArray()
  @ValidateNested({ each: true })
  @Type(() => Param)
  params: Param[];

  @IsArray()
  config: GLDFormConfig[];

  @IsBoolean()
  cloneControlGroup: boolean;

  @IsBoolean()
  adjustableUsersAllocation: boolean;

  @IsBoolean()
  newUsers: boolean;

  @IsNumberString()
  usersAllocationPercent: string;

  static ofInitial() {
    const form = new GLDConfigParams();
    form.defaultValue = '';
    form.params = [];
    form.cloneControlGroup = false;
    form.config = [
      {
        name: 'A',
        description: 'Control Group',
        input: []
      }
    ];
    form.adjustableUsersAllocation = false;
    form.newUsers = true;
    form.usersAllocationPercent = '100';
    return form;
  }

  getSummary(): ExpFormSummary {
    const fields = [
      { title: 'Clone Control Group', value: String(this.cloneControlGroup) },
      { title: 'Adjustable Percent', value: String(this.adjustableUsersAllocation) },
      { title: 'New Users', value: String(this.newUsers) },
      { title: 'Percentage of engaged users', value: `${this.usersAllocationPercent}%` }
    ];

    return fields;
  }
}
