import { ArrayMinSize, IsArray, IsNotEmpty, ValidateNested } from 'class-validator';
import { Transform, Type } from 'class-transformer';
import { UniqueId } from '@domain/types';
import { RegionDto } from '@domain/models/RegionDto';

export class WinnerInput {
  regionName: string;
  regionCode: string;
  group: string;
  applied: boolean;

  setDefaultValues() {
    this.regionName = '';
    this.regionCode = '';
    this.group = '';
    this.applied = false;
  }
}

export class VersionInput {
  @IsNotEmpty()
  value: string;
}

export class WinnerConfigFormParams {
  regions: RegionDto[];

  @IsArray()
  @Transform(({ value }) => value.filter((winner) => winner.applied))
  @ArrayMinSize(1)
  winners: WinnerInput[];

  @IsArray()
  @ValidateNested({ each: true })
  @Type(() => VersionInput)
  version: VersionInput[];

  setDefaultValues() {
    this.regions = [];
    this.winners = [];
    this.version = [{ value: '' }];

    return this;
  }
}

export class UpdateWinnerConfigParams extends WinnerConfigFormParams {
  experimentId: UniqueId;
  objectiveId: UniqueId;
}
